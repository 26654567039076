import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import './datePicker.css'
import DatePicker from 'react-datepicker'
import { CalendarIcon } from 'assets'

function CustomDatePicker(props) {
    const { label, name, placeholder, rules, className, ...rest } = props
    const { control } = useFormContext()
    return (
        <div className='form-group'>
            <label className='text-capitalize lable-wrap'>
                {label}
                {rules?.required && <span className='text-danger '>*</span>}
            </label>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field }) => (
                    <DatePicker
                        selected={field.value}
                        // onChange={(date) => field.onChange(date)}
                        className={`form-control custom-date-picker-input ${className}`}
                        icon={<CalendarIcon />}
                        showIcon={true}
                        calendarIconClassname='custom-date-picker-icon'
                        popperClassName='custom-popper-date-picker'
                        dateFormat={'yyyy-MM-dd'}
                        placeholderText={placeholder}
                        {...rest}
                    />
                )}
            />
        </div>
    )
}

export default CustomDatePicker
